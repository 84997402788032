import React, {useContext, useEffect, useState} from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined ,DownOutlined,UserOutlined,SettingOutlined,LoginOutlined,LockOutlined,EyeTwoTone,EyeInvisibleOutlined} from '@ant-design/icons';
import {Button, Layout, Dropdown, Space, Modal, Select ,Card,Input,Form} from 'antd';
import langContext from "../helpers/Context/lang-context";

import { useNavigate } from 'react-router-dom';

import ResetModal from "../Components/ResetPassword/ResetModal";
import UserContext from "../helpers/Context/user-context";
const { Header } = Layout;

const TopNavBar = ({collapsed, setCollapsed}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [lang, setLang] = useState('en');
  const navigate = useNavigate()
  const ctx=useContext(langContext)
  const ctx_user = useContext(UserContext);

  const t=ctx.t;

  const logout = () => {
    ctx_user.setUser(null);
    ctx_user.setUserInfo({
      name: '',
      password: '',
    });
    localStorage.removeItem("token_gasv");
    navigate('/login')
  }
  
  const copyLinkTwo=(agentId)=> {
    navigator.clipboard.writeText("https://gasv388.net/register?ag_id="+agentId);
  }
  const copyLinkOne=(agentId)=> {
    navigator.clipboard.writeText("https://ga6789.net/register?ag_id=" + agentId);
  }
  const handleMenuClick = (e) => {
    // console.log('click', e);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const items = [

    {
      label: 'Reset Password',
      key: '4',
      icon: <LockOutlined />,
      onClick:showModal,
      danger: false,
    },
      {
      label: 'Logout',
      key: '1',
      icon: <LoginOutlined />,
      onClick:logout,
      danger: true
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };


  useEffect(()=>{
    ctx.setLocale(lang)
  },[lang])
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
    ctx.setLocale(lang)
    setLang(value)
  };
  return (

    <Header style={{  background: "white", display:'flex', alignItems:'center', justifyContent:'space-between' }}>
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
        })}
      <Space wrap>
        <Select
            defaultValue={t.english}
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: "en",
                label: t.english,
              },
              {
                value: "vn",
                label: t.vietnamese,
              }
            ]}
        />

          <Dropdown menu={menuProps}>
            <Button>
              <Space>
                {t.settings} <SettingOutlined />
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>

      </Space>

      <Modal  footer={false} title={t.reset+' '+t.password} open={isModalOpen} >
          <ResetModal onCancel={handleCancel}></ResetModal>
      </Modal>
    </Header>
  )
}

export default TopNavBar