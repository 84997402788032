import React, { useState } from 'react';
import LangContext from './lang-context';
import Lang from '../../messages';

const LangProvider = (props) => {
    const [locale, setLoc] = useState('vn');
    const [t, setT] = useState(Lang);
    const setLocale = (language) => {
        setLoc(language);
        t.setLanguage(language);

    }

    const langContext = {
        locale: locale,
        t:t,
        setLocale: setLocale
    };

    return (
        <LangContext.Provider value={langContext}>
            {props.children}
        </LangContext.Provider>
    )
}

export default LangProvider;