import {useNavigate} from "react-router-dom";

export const Url = ()=>  {
    let application='sv';
    let baseAPI,baseWEB1,baseWEB2;
        switch (application) {
            case 'ga':
                baseAPI= 'https://bogaproject.com/agent';
                baseWEB1='https://ga6789.net/register?ag_id=';
                baseWEB2='https://gasv388.net/register?ag_id=';
                break;
            case 'sv':
                baseAPI= 'https://bo.gavn138.com/agent';
                baseWEB1='https://gavn138.com/register?ag_id=';
                baseWEB2=baseWEB1;
                break;
            case 'tg':
                baseAPI= 'https://bo.tggenting.com/agent';
                baseWEB1='https://tggenting.com/register?ag_id=';
                baseWEB2=baseWEB1;
                break;
            case 'gawin':
                baseAPI= 'https://bo.ga6789.info/agent';
                baseWEB1='https://ga6789.info/register?ag_id=';
                baseWEB2=baseWEB1;
                break;
            case 'sv368':
                baseAPI= 'https://bo.vaosv368.com/agent';
                baseWEB1='https://vaosv368.com/register?ag_id=';
                baseWEB2=baseWEB1;
                break;
            default:
                baseAPI= 'http://127.0.0.1:8000/agent';
                baseWEB1='http://127.0.0.1:8000/register?ag_id=';
                baseWEB2=baseWEB1;
                break;
        }
    return {baseAPI:baseAPI,baseWEB1:baseWEB1,baseWEB2:baseWEB2 };
}
