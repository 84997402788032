import React, {useEffect,useContext, useState} from "react";
import { CSVLink } from "react-csv";

import {useNavigate, useSearchParams} from 'react-router-dom';
import {
    addCommasToNumber,
    APIDownloadFile, APIDownloadTrabsactionFile,
    APITransactions,
    makeServerDate,
    removeCommasFromNumber
} from "../../helpers/APIs/UserAPIs";
import AuthLayout from "../../Layout/AuthLayout";
import {Table, Card, Tag, Row, DatePicker, Space, Button, Input, Select, Divider, Form} from "antd";
import langContext from "../../helpers/Context/lang-context";
import StatisticCard from "../../Components/Cards/StatisticCard";
import userContext from "../../helpers/Context/user-context";
import download from "react-csv/src/components/Download";
const { RangePicker } = DatePicker;

const Transactions = () => {
    const [dataSourse, setDataSourse] = useState([]);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalWithdraw, setTotalWithdraw] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [transactionType, setTransactionType] = useState('all');
    const [phone, setPhone] = useState(null);
    const [amount, setAmount] = useState(null);
    const [date, setDate] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const ctx_user=useContext(userContext)
    const ctx=useContext(langContext)
    const t=ctx.t;
    const { Search } = Input;


    const fetchData = async (formValue) => {
        setLoading(true);
        const response = await APITransactions(formValue)
        if(response){
            if (response === "TOKEN_EXPIRED") {
                localStorage.removeItem('token_gasv')
                ctx_user.setUser('');
                navigate('/login')
            }else{
                setTotalDeposit(response.totalDeposit);
                setTotalWithdraw(response.totalWithDraw);
                setTotalCount(response.totalCount);
                if(response.account){
                    setDataSourse(response.account.data);
                    setTotalPages(response.account.total);
                    setPageSize(response.account.pageSize);
                    setPage(response.account.current_page);
                }
            }
        }
        setLoading(false);
    };

    const formatDate = (dateString) => {
        const options = {year: "numeric", month: "long", day: "numeric"}
        return new Date(dateString).toLocaleDateString("en-US", options)
    }
    const columns = [
        {
            title: t.phone,
            dataIndex: 'player_phone',
        },
        {
            title: t.amount,
            dataIndex: 'transaction_amount',
            render: (total) => (addCommasToNumber(total??0)),
        },
        {
            title: t.type,
            dataIndex: 'transaction_purpose',
            render: (tag,record) => (
                <>
                <Tag color={tag === 'deposit' ? 'green' : 'volcano'}> {tag === 'deposit' ? t.deposit.toUpperCase() : t.withdraw.toUpperCase()}</Tag>
                    {tag=== 'deposit'&& record.user && record.user.first_transaction && record.user.first_transaction.id && record.id===record.user.first_transaction.id && <Tag color={'geekblue'}>new</Tag>}

                </>
            ),
        },
        {
            title: t.date,
            dataIndex: 'created_at',
            render: (created_at) => formatDate(created_at),
        },
    ];
    const footerValues = () => {
        return (
            <div style={{ display: "flex" }}>
                <p style={{ marginRight: "20px" }}>
                    {t.total_deposit}: <strong>{addCommasToNumber(totalDeposit)}</strong>
                </p>
                <p style={{ marginRight: "20px" }}>
                    {t.total_withdraw}: <strong>{addCommasToNumber(totalWithdraw)}</strong>
                </p>
                <p style={{ marginRight: "20px" }}>
                    {t.total_amount}: <strong>{addCommasToNumber(totalDeposit + totalWithdraw)}</strong>
                </p>
                <p style={{ marginRight: "20px" }}>
                    {t.total_profit}: <strong>{addCommasToNumber(totalDeposit - totalWithdraw)}</strong>
                </p>
                <p style={{ marginRight: "20px" }}>
                    {t.total_count}: <strong>{totalCount}</strong>
                </p>
            </div>
        );
    }
    const sendRequestToGetTransaction = (values) => {

        setPhone(values.phone);
        setAmount(removeCommasFromNumber(values.amount??0) );
        setTransactionType(values.transactionType);
        setPageSize(values.pageSize);
        setPage(values.page);
        setDate(values.date);
        fetchData({
            page:values.page,
            phone:values.phone,
            amount:removeCommasFromNumber(values.amount??0),
            pageSize:values.pageSize,
            type:values.transactionType,
            date: [
            values.date ? values.date[0] ? makeServerDate(values.date[0]) : null:null,
            values.date ? values.date[1] ? makeServerDate( values.date[1]) : null:null,
        ],

        });
    }
    useEffect(() => {
        let start_date= searchParams.get('start_date');
        let end_date= searchParams.get('end_date');
        let filterType= searchParams.get('type');
        sendRequestToGetTransaction({
            date:[start_date ? new Date(start_date) : null, end_date ? new Date(end_date) : null],
            transactionType:filterType?filterType:null,
        });
    }, [])

    const exportAsExcel=()=>{
       const res=APIDownloadTrabsactionFile({
            phone:phone,
            amount:amount,
           type:transactionType,
               date: [
                   date ? date[0] ?  date[0].toLocaleString('en-US') : null:null,
                   date ? date[1] ?  date[1].toLocaleString('en-US') : null:null,
               ],
            pageSize:pageSize,
            page:page,
            exportExcel:true,


        })
    }

    return (
            <Card title={t.transactions}>
                <Card title={t.filters} type={'inner'}>
                    <Form
                        name="filterForm"
                        layout="inline"
                        onFinish={sendRequestToGetTransaction}
                    >
                        <Form.Item name="date" label={t.select_date}>
                            <RangePicker/>
                        </Form.Item>
                        <Form.Item name={'phone'} label={t.phone}>
                            <Input  value={phone}/>
                        </Form.Item  >
                        <Form.Item name={'amount'} label={t.amount}>
                            <Input value={amount} />
                        </Form.Item>
                        <Form.Item  name={'transactionType'}  label={t.type} initialValue={transactionType}>
                            <Select style={{width:'100px'}}>
                                <Select.Option  value="all">{t.all}</Select.Option>
                                <Select.Option value="deposit">{t.deposit}</Select.Option>
                                <Select.Option value="withdraw">{t.withdraw}</Select.Option>
                                <Select.Option value="new">{t.new }</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {t.submit}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={exportAsExcel}>
                                Export
                            </Button>
                        </Form.Item>

                    </Form>

                </Card>
                <Divider/>
            <Card title={t.transactions}  type={'inner'}  bordered={true}>

                <Table bordered loading={loading} columns={columns}  dataSource={dataSourse} footer={footerValues} pagination={
                    {
                        current: page, pageSize: pageSize, total: totalPages, onChange: (pageNumber, pageSize) => {
                            setPage(pageNumber);
                            setPageSize(pageSize);
                            sendRequestToGetTransaction({
                                page: pageNumber,
                                phone: phone,
                                amount: amount,
                                pageSize: pageSize,
                                transactionType: transactionType,
                                date: date,

                            });
                        }
                    }
                }/>
            </Card>
            </Card>
    )
}
export default Transactions