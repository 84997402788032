import {Card, Col, Row, Statistic} from "antd";
import {ArrowDownOutlined, TeamOutlined,IdcardOutlined,UserSwitchOutlined} from "@ant-design/icons";
import React, {useContext} from "react";
import langContext from "../../helpers/Context/lang-context";
import userContext from "../../helpers/Context/user-context";

const StatisticCard = (props) => {
    return(<Col span={6}>
                <Card bordered={true} hoverable >
                    <Statistic
                        title={props.title}
                        value={props.value}
                        // precision={2}
                        valueStyle={{
                            color: '#3f8600',
                        }}
                        prefix={<TeamOutlined />}
                        suffix={props.suffix}
                    />
                </Card>
            </Col>)

}
export default StatisticCard